<template>
  <router-view />
</template>

<script setup>
import axios from "axios";
import { onMounted } from "vue";

onMounted(async () => {
  const favicon = await axios.get(`/favicon`);
  document.querySelector('link[rel="icon"]').href = favicon.data;
});
</script>

<style lang="sass">
@import "./assets/sass/all"
@import '@/assets/sass/team/airconditioner/difans.sass'
@import '@/assets/sass/team/hydropwer/hydropwer_45c.sass'
@import '@/assets/sass/team/systemcabinet/systemcabinet.sass'

body,html
  overflow: hidden
  ::-webkit-scrollbar-track
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3)
    background-color: white
//捲軸寬度
  ::-webkit-scrollbar
    width: 6px
    background-color: black
//捲軸本體顏色
  ::-webkit-scrollbar-thumb
    background-color: #94A0B2
#app
  overflow: hidden
  overflow-y: scroll

section#section_process .process_all_box .process_icon_box .process_icon img,
#list_pr .list_pr_box .list_p img,
.custom_content img,
#custom_page_wrapper img,
#section_shop_commodity img,
section#qa_box .qa_box_qution .qa_box .qa_intext .text_box img
  max-width: 100%
  height: auto !important
</style>
