import { createRouter, createWebHashHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';

import IndexView from '../views/IndexView.vue';

import CustomPageIndexView from '../views/CustomPageView.vue';
import CustomPageDetailView from '../views/CustomPageView/CustomPageView.vue';

import NewsIndexView from '../views/NewsView.vue';
import NewsListView from '../views/NewsView/NewsListView.vue';
import NewsDetailView from '../views/NewsView/NewsView.vue';

import AboutView from '../views/AboutView.vue';

import TaemView from '../views/TaemView.vue';
import DesignerView from '../views/TaemView/DesignerView.vue';
import LicenseView from '../views/TaemView/LicenseView.vue';

import ProjectView from '../views/ProjectView.vue';
import WorkView from '../views/ProjectView/WorkView.vue';
import WorkImagesView from '../views/ProjectView/WorkImages.vue';
import WorkProductView from '../views/ProjectView/WorkProduct.vue';

import VideoView from '../views/ProjectView/VideoView.vue';

import CallView from '../views/CallView.vue';

import FAQView from '../views/FAQView.vue';
import QaView from '../views/FAQView/QaView.vue';

import KnowledgeIndexView from '../views/KnowledgeView.vue';
import KnowledgeListView from '../views/KnowledgeView/KnowledgeListView.vue';
import KnowledgeDetailView from '../views/KnowledgeView/KnowledgeView.vue';

import SearchView from '../views/SearchView.vue';

import ManpowerView from '../views/Manpower/ManpowerView.vue'
import ManpowerPr from '../views/Manpower/ManpowerPr.vue'
import ManpowerPush from '../views/Manpower/ManpowerPush.vue'

import Shop_Cart from '../views/ShopView/Shop_Cart.vue'
import Shop_CeatePr from '../views/ShopView/Shop_CeatePr.vue'

import ShopView from '../views/ShopView.vue';
import Shop_indexView from '../views/ShopView/Shop_indexView.vue';
import ShopPr_show from '../views/ShopView/ShopPr_show.vue';
import Shop_pr from '../views/ShopView/Shop_pr.vue';
import ShopPrivacy from '../views/ShopView/ShopPrivacy.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: {
      name: 'index',
    },
    children: [
      {
        path: '/index',
        name: 'index',
        meta: {
          page_name: '首頁',
          eng_page_name: 'home',
        },
        component: IndexView,
      },
      {
        path: '/custom_page',
        component: CustomPageIndexView,
        children: [
          {
            path: ':title',
            meta: {
              page_name: '自訂頁面',
              eng_page_name: 'custom_page',
            },
            component: CustomPageDetailView,
          },
        ],
      },
      {
        path: '/news',
        component: NewsIndexView,
        children: [
          {
            path: '',
            meta: {
              page_name: '最新消息',
              eng_page_name: 'news',
            },
            component: NewsListView,
          },
          {
            path: ':id',
            meta: {
              page_name: '最新消息',
              eng_page_name: 'news',
              detail_page: '1',
            },
            component: NewsDetailView,
          },
        ],
      },
      {
        path: '/test',
        component: () => import('../views/Product_Test.vue'),
      },
      {
        path: '/about',
        meta: {
          page_name: '關於安心',
          eng_page_name: 'about',
        },
        component: AboutView,
      },
      {
        path: '/team',
        component: TaemView,
        children: [
          {
            path: ':url_name',
            meta: {
              page_name: '服務團隊',
              eng_page_name: 'team',
            },
            component: DesignerView,
          },
          // {
          //   path: 'hydropower',
          //   component: () => import('../views/TaemView/HydropowerView.vue'),
          // },
          // {
          //   path: 'airconditioner',
          //   component: () => import('../views/TaemView/AirconditionerView.vue'),
          // },
          // {
          //   path: 'mud',
          //   component: () => import('../views/TaemView/MudView.vue'),
          // },
          // {
          //   path: 'wooden',
          //   component: () => import('../views/TaemView/WoodenView.vue'),
          // },
          // {
          //   path: 'paint',
          //   component: () => import('../views/TaemView/PaintView.vue'),
          // },
          // {
          //   path: 'sales',
          //   component: () => import('../views/TaemView/SalesView.vue'),
          // },
          // {
          //   path: 'aluminiumwindow',
          //   component: () => import('../views/TaemView/AluminiumwindowView.vue'),
          // },
          // {
          //   path: 'systemcabinet',
          //   component: () => import('../views/TaemView/SystemcabinetView.vue'),
          // },
          // {
          //   path: 'overseer',
          //   component: () => import('../views/TaemView/OverseerView.vue'),
          // },
          {
            name: 'team_staff',
            path: 'staff/:id',
            meta: {
              page_name: '服務團隊',
              eng_page_name: 'team',
            },
            component: LicenseView,
          },
        ],
      },
      {
        path: '/project',
        component: ProjectView,
        children: [
          {
            name: 'engineering_list',
            path: 'work',
            meta: {
              page_name: '精選作品',
              eng_page_name: 'engineering',
            },
            component: WorkView,
          },
          {
            name: 'engineering_images',
            path: 'images/:id',
            meta: {
              page_name: '精選作品',
              eng_page_name: 'engineering',
            },
            component: WorkImagesView,
          },
          {
            name: 'engineering',
            path: '/project/:id',
            meta: {
              page_name: '精選作品',
              eng_page_name: 'engineering',
              detail_page: '1',
            },
            component: WorkProductView,
          },
          {
            path: 'video',
            meta: {
              page_name: '影音圖集',
              eng_page_name: 'video',
            },
            component: VideoView,
          },
        ],
      },
      {
        path: '/call',
        meta: {
          page_name: '聯絡資訊',
          eng_page_name: 'contact',
        },
        component: CallView,
      },
      {
        path: '/faq',
        component: FAQView,
        children: [
          {
            path: '',
            meta: {
              page_name: '常見問題',
              eng_page_name: 'faq',
            },
            component: QaView,
          },
        ],
      },
      {
        path: '/knowledge',
        component: KnowledgeIndexView,
        children: [
          {
            name: 'knowledge_list',
            path: '',
            meta: {
              page_name: '知識分享',
              eng_page_name: 'knowledge',
            },
            component: KnowledgeListView,
          },
          {
            name: 'knowledge',
            path: ':id',
            meta: {
              page_name: '知識分享',
              eng_page_name: 'knowledge',
              detail_page: '1',
            },
            component: KnowledgeDetailView,
          },
        ],
      },

      {
        name: 'search_all',
        path: '/search/all/:id',
        meta: {
          page_name: '搜尋頁面',
          eng_page_name: 'home',
        },
        component: SearchView,
      },
      {
        name: 'search_shop',
        path: '/search/shop/:id',
        meta: {
          page_name: '搜尋頁面',
          eng_page_name: 'prodcut',
        },
        component: SearchView,
      },
      {
        name: 'search_1',
        path: '/search/shop/:url_name1/:id',
        meta: {
          page_name: '搜尋頁面',
          eng_page_name: 'prodcut',
        },
        component: SearchView,
      },
      {
        name: 'search_2',
        path: '/search/shop/:url_name1/:url_name2/:id',
        meta: {
          page_name: '搜尋頁面',
          eng_page_name: 'prodcut',
        },
        component: SearchView,
      },
      {
        name: 'search_3',
        path: '/search/shop/:url_name1/:url_name2/:url_name3/:id',
        meta: {
          page_name: '搜尋頁面',
          eng_page_name: 'prodcut',
        },
        component: SearchView,
      },

      {
        path: '/manpower',
        meta: {
          page_name: '安心職缺',
          eng_page_name: 'work',
        },
        component: ManpowerView,
      },
      {
        path: '/manpower/:id',
        meta: {
          page_name: '安心職缺',
          eng_page_name: 'work',
          detail_page: '1',
        },
        component: ManpowerPr,
      },
      {
        path: '/manpower/push',
        meta: {
          page_name: '安心推薦',
          eng_page_name: 'recommend',
        },
        component: ManpowerPush,
      },
      {
        path: '/shop/cart',
        meta: {
          page_name: '詢價單',
          eng_page_name: 'prodcut',
        },
        component: Shop_Cart,
      },
      {
        path: '/shop/cart/:id',
        meta: {
          page_name: '詢價單',
          eng_page_name: 'prodcut',
        },
        component: Shop_CeatePr,
      },
      {
        path: '/login',
        component: () => import('../views/LoginView.vue'),
      },
      {
        path: '/register',
        component: () => import('../views/NumberView/RegisterView.vue'),
      },
    ],
  },
  {
    path: '/shop',
    component: ShopView,
    children: [
      {
        path: '',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
        },
        component: Shop_indexView,
      },
      {
        name: 'shop_level_1',
        path: ':url_name1',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
        },
        component: ShopPr_show,
      },
      {
        name: 'shop_level_2',
        path: ':url_name1/:url_name2',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
        },
        component: ShopPr_show,
      },
      {
        name: 'shop_level_3',
        path: ':url_name1/:url_name2/:url_name3',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
        },
        component: ShopPr_show,
      },
      {
        name: 'product_level_1',
        path: 'pr/:url_name1/:id',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
          detail_page: '1',
        },
        component: Shop_pr,
      },
      {
        name: 'product_level_2',
        path: 'pr/:url_name1/:url_name2/:id',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
          detail_page: '1',
        },
        component: Shop_pr,
      },
      {
        name: 'product_level_3',
        path: 'pr/:url_name1/:url_name2/:url_name3/:id',
        meta: {
          page_name: '商品介紹',
          eng_page_name: 'prodcut',
          detail_page: '1',
        },
        component: Shop_pr,
      },
      {
        path: 'question/:url_name',
        meta: {
          page_name: '購買問題',
          eng_page_name: 'prodcut',
        },
        component: ShopPrivacy,
      },
      // {
      //   path: '/shop/purchasenotes',
      //   component: () => import('../views/ShopView/ShopPurchasenotes.vue'),
      // },
      // {
      //   path: '/shop/canceltheorder',
      //   component: () => import('../views/ShopView/ShopCanceltheorder.vue'),
      // },
      // {
      //   path: '/shop/paymentdelivery',
      //   component: () => import('../views/ShopView/ShopPaymentdelivery.vue'),
      // },
      // {
      //   path: '/shop/membershipservice',
      //   component: () => import('../views/ShopView/ShopMembershipservice.vue'),
      // },
    ],
  },
  {
    path: '/member',
    component: () => import('../views/NumberView/BackGroundvue.vue'),
    children: [
      {
        path: 'user',
        component: () => import('../views/NumberView/MemberUser.vue'),
      },
      {
        path: 'order',
        component: () => import('../views/NumberView/MemberOrderlist.vue'),
      },
      {
        path: 'order/:id',
        component: () => import('../views/NumberView/MemberProduct.vue'),
      },
    ],
  },
  {
    path: '/backstage',
    component: () => import('../views/backbround/BackGround.vue'),
    children: [
      {
        path: 'homepage',
        component: () => import('../views/backbround/HomepageView.vue'),
      },
      {
        path: 'nav&foot',
        component: () => import('../views/backbround/BackNav&foot.vue'),
      },
      {
        path: 'index',
        component: () => import('../views/backbround/BackIndex.vue'),
      },
      {
        path: 'about',
        component: () => import('../views/backbround/BackAbout.vue'),
      },
      {
        path: 'team',
        component: () => import('../views/backbround/BackTeam.vue'),
        children: [
          {
            path: 'designer',
            component: () => import('../views/backbround/Team/BackDesigner.vue'),
          },
          {
            path: 'airconditioner',
            component: () => import('../views/backbround/Team/BackAirconditioner.vue'),
          },
          {
            path: 'aluminiumwindow',
            component: () => import('../views/backbround/Team/BackAluminiumwindow.vue'),
          },
          {
            path: 'hydropower',
            component: () => import('../views/backbround/Team/BackHydropower.vue'),
          },
          {
            path: 'mud',
            component: () => import('../views/backbround/Team/BackMud.vue'),
          },
          {
            path: 'paint',
            component: () => import('../views/backbround/Team/BackPaint.vue'),
          },
          {
            path: 'sales',
            component: () => import('../views/backbround/Team/BackSales.vue'),
          },
          {
            path: 'systemcabinet',
            component: () => import('../views/backbround/Team/BackSystemcabinet.vue'),
          },
          {
            path: 'wooden',
            component: () => import('../views/backbround/Team/BackWooden.vue'),
          },
        ],
      },
      {
        path: 'project',
        component: () => import('../views/backbround/BackProject.vue'),
        children: [
          {
            path: 'viedo',
            component: () => import('../views/backbround/Project/BackVideo.vue'),
          },
          {
            path: 'work',
            component: () => import('../views/backbround/Project/BackWork.vue'),
          },
        ],
      },
      {
        path: 'pr/:id',
        component: () => import('../views/backbround/Project/BackPr.vue'),
      },
      {
        path: 'pr/newpr',
        component: () => import('../views/backbround/Project/BackPr.vue'),
      },
      {
        path: 'shop',
        component: () => import('../views/backbround/BackShop.vue'),
      },
      {
        path: 'shop_edit/:id',
        component: () => import('../views/backbround/Shop/BackShop_edit.vue'),
      },
      {
        path: 'shop_edit/edit',
        component: () => import('../views/backbround/Shop/BackShop_edit.vue'),
      },
      {
        path: 'call',
        component: () => import('../views/backbround/BackCall.vue'),
      },
      {
        path: 'faq',
        component: () => import('../views/backbround/BackFaq.vue'),
        children: [
          {
            path: 'list',
            component: () => import('../views/backbround/Faq/BackList.vue'),
          },
          {
            path: 'qa',
            component: () => import('../views/backbround/Faq/BackQa.vue'),
          },
        ],
      },
      {
        path: 'list/edit',
        component: () => import('../views/backbround/Faq/BackList_edit.vue'),
      },
      {
        path: 'list/:id',
        component: () => import('../views/backbround/Faq/BackList_edit.vue'),
      },
      {
        path: 'qa/edit',
        component: () => import('../views/backbround/Faq/BackQa_edit.vue'),
      },
      {
        path: 'qa/:id',
        component: () => import('../views/backbround/Faq/BackQa_edit.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'index',
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
