import { defineStore } from 'pinia'

export const useShoppingCartStore = defineStore('shopping_cart', () => {

  const add = (product) => {
    const shopping_cart = getShoppingCart();

    let find = false;

    shopping_cart.forEach((ele) => {
      if (
        ele.pid == product.pid
        && ele.goods_number == product.goods_number
        && ele.name == product.name
        && ele.spec1_name == product.spec1_name
        && ele.spec2_name == product.spec2_name
        && ele.spec3_name == product.spec3_name
        && ele.type == product.type
        // && $product['origin_price'] == $request->origin_price
        // && $product['price'] == $request->price
      ) {
        find = true;
        ele.qty = product.qty;
        ele.price = product.price;
      }
    })

    if (!find) {
      shopping_cart.push(product)
    }

    setShoppingCart(shopping_cart)
  }

  const get = () => {
    const shopping_cart = getShoppingCart();

    const result = []

    shopping_cart.forEach((ele) => {
      result.push({
        _type: ele.type,
        tital: ele.name,
        spec1_name: ele.spec1_name,
        spec2_name: ele.spec2_name,
        spec3_name: ele.spec3_name,
        pid: ele.pid,
        goods_number: ele.goods_number,
        price: ele.price,
        origin_price: ele.origin_price,
        img_url: '/upload/images/product/list/' + ele.list_image,
        number: ele.qty,
        fanelprice: ele.price * ele.qty,
        to: ele.path,
      });
    });

    return result;
  }

  const update_qty = (product) => {
    const shopping_cart = getShoppingCart();

    shopping_cart.forEach((ele) => {
      if (
        ele.pid == product.pid
        && ele.goods_number == product.goods_number
        && ele.name == product.name
        && ele.spec1_name == product.spec1_name
        && ele.spec2_name == product.spec2_name
        && ele.spec3_name == product.spec3_name
        && ele.type == product.type
        // && $product['origin_price'] == $request->origin_price
        // && $product['price'] == $request->price
      ) {
        ele.qty = product.qty;
        ele.price = product.price;
      }
    })

    setShoppingCart(shopping_cart)
  }

  const remove = (product) => {
    const shopping_cart = getShoppingCart();

    const result = []

    shopping_cart.forEach((ele) => {
      if (
        ele.pid != product.pid
        || ele.goods_number != product.goods_number
        || ele.name != product.name
        || ele.spec1_name != product.spec1_name
        || ele.spec2_name != product.spec2_name
        || ele.spec3_name != product.spec3_name
        || ele.type != product.type
        // && $product['origin_price'] == $request->origin_price
        // && $product['price'] == $request->price
      ) {
        result.push(ele)
      }
    })

    setShoppingCart(result)
  }

  const getShoppingCart = () => {
    let shopping_cart = localStorage.getItem('shopping_cart_ver3');
    shopping_cart = (shopping_cart === null) ? [] : JSON.parse(shopping_cart)
    return shopping_cart;
  }

  const setShoppingCart = (shopping_cart) => {
    console.log('setShoppingCart', shopping_cart)
    shopping_cart = JSON.stringify(shopping_cart)
    localStorage.setItem('shopping_cart_ver3', shopping_cart);
  }

  return { add, get, update_qty, remove, getShoppingCart, setShoppingCart }
})